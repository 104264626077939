import React from "react";

const Services = ({ t }) => {
  return (
    <div className="cmp-services__container" id="services">
      <div className="cmp-services__description">
        <p className="cmp-services__description-title">{t("services-title")}</p>
      </div>
      <div className="cmp-services__services">
        <div className="cmp-services__service-item item_1">
          <div className="cmp-services__service-item-image" />
          <div className="cmp-services__service-item-summary">
            <div className="cmp-services__service-item-summary-title">
              {t("service_1-title")}
            </div>
            <p className="cmp-services__service-item-summary-text">
              {t("service_1")}
            </p>
          </div>
        </div>

        <div className="cmp-services__service-item item_2">
          <div className="cmp-services__service-item-image" />
          <div className="cmp-services__service-item-summary">
            <div className="cmp-services__service-item-summary-title">
              {t("service_2-title")}
            </div>
            <p className="cmp-services__service-item-summary-text">
              {t("service_2")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
