import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

const Navigation = ({ t, changeLanguage }) => {
  document.addEventListener("scroll", function () {
    const navbar = document.querySelector(".navbar");
    const navbarHeight = 100;
    const distanceFromTop = Math.abs(document.body.getBoundingClientRect().top);
    if (distanceFromTop >= navbarHeight) navbar.classList.add("fixed-top");
    else navbar.classList.remove("fixed-top");
  });

  return (
    <Navbar className="navbar" bg="light" expand="lg" collapseOnSelect={true}>
      <Container fluid>
        <Navbar.Brand href="#top" className="cmp-navbar__logo">
          <img alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "400px" }}
            navbarScroll
          >
            <Nav.Link href="#about">{t("about")}</Nav.Link>
            <Nav.Link href="#services"> {t("services")}</Nav.Link>
            <Nav.Link href="#faq"> F.A.Q.</Nav.Link>
            <Nav.Link href="#contact"> {t("contact")}</Nav.Link>
            <NavDropdown title={t("languages")} id="navbarScrollingDropdown">
              <NavDropdown.Item onClick={() => changeLanguage("fr")}>
                FR
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeLanguage("en")}>
                EN
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
