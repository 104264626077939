import "./App.scss";
import Navigation from "./Components/Navigation/Navigation.jsx";
import { withNamespaces } from "react-i18next";
import i18n from "./Translations/i18n";
import Hero from "./Components/Hero/Hero";
import BusinessDescription from "./Components/BusinessDescription/BusinessDescription";
import ToolDescription from "./Components/ToolDescription/ToolDescription";
import Services from "./Components/Services/Services";
import Contact from "./Components/Contact/Contact";
import Faq from "./Components/Faq/Faq";
import Footer from "./Components/Footer/Footer";

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};

function App({ t }) {
  return (
    <div className="App">
      <Navigation t={t} changeLanguage={changeLanguage} />
      <div className="content">
        <Hero t={t} />
        <BusinessDescription t={t} />
        <ToolDescription t={t} />
        <Services t={t} />
        <Faq t={t} />
        <Contact t={t} />
        <Footer />
      </div>
    </div>
  );
}

export default withNamespaces()(App);
