import React from "react";
import { Accordion } from "react-bootstrap";

const Faq = ({ t }) => {
  return (
    <div id="faq" className="cmp-faq__container">
      <h1>F.A.Q.</h1>
      <Accordion className="cmp-faq__container-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t("faq_1")}</Accordion.Header>
          <Accordion.Body>{t("faq_a_1")}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t("faq_2")}</Accordion.Header>
          <Accordion.Body>{t("faq_a_2")}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{t("faq_3")}</Accordion.Header>
          <Accordion.Body>{t("faq_a_3")}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{t("faq_4")}</Accordion.Header>
          <Accordion.Body>{t("faq_a_4")}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Faq;
