import React from "react";

const BusinessDescription = ({ t }) => {
  return (
    <footer class="flex-shrink-0 py-4 bg-dark text-white-50">
      <div class="container text-center">
        <small>
          Copyright &copy; Teammate performance {new Date().getFullYear()}
        </small>
      </div>
    </footer>
  );
};

export default BusinessDescription;
