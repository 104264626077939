import { React, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import emailjs from "emailjs-com";
import Recaptcha from "react-recaptcha";

const Contact = ({ t }) => {
  const form = useRef();
  const [isVerified, setIsVerified] = useState(false);

  const handleSubmit = (event) => {
    if (!isVerified) {
    } else {
      event.preventDefault();
      emailjs
        .sendForm(
          "service_a4pfo0t",
          "template_xuzpi51",
          form.current,
          "BbPse93vUefFFIfwk"
        )
        .then(
          (result) => {
            console.log(result.text);
            alert("Your message has been sent");
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  const verifyCallback = (response) => {
    console.log(response);
    if (response) {
      setIsVerified({
        isVerified: true,
      });
    }
  };

  const recaptchaLoaded = () => {
    console.log("capcha successfully loaded");
  };

  return (
    <div className="cmp-contact__container" id="contact">
      <div className="cmp-contact__form">
        <div className="cmp-contact__form-group">
          <h1 className="cmp-contact__form-title">{t("contact-title")}</h1>
          <Form
            className="cmp-contact-form-container"
            ref={form}
            onSubmit={handleSubmit}
          >
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3" controlId="formLastName">
                <Form.Label>{t("name")}</Form.Label>
                <Form.Control type="text" required name="lastName" />
              </Form.Group>
              <Form.Group as={Col} className="mb-3" controlId="formName">
                <Form.Label>{t("firstname")}</Form.Label>
                <Form.Control type="text" required name="firstName" />
              </Form.Group>
            </Row>
            <Form.Group className="mb-3" controlId="formCompany">
              <Form.Label>{t("companyName")}</Form.Label>
              <Form.Control type="text" required name="companyName" />
            </Form.Group>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3" controlId="formEmail">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control type="email" required name="email" />
              </Form.Group>
              <Form.Group as={Col} className="mb-3" controlId="formPhone">
                <Form.Label>{t("PhoneNumber")}</Form.Label>
                <Form.Control type="tel" required name="phoneNumber" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="mb-3" controlId="formPhone">
                <Form.Label>{t("NumberOfPeople")}</Form.Label>
                <Form.Control
                  type="number"
                  min={4}
                  required
                  name="numberOfPeople"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <div key={"tpi"} className="mb-3">
                  <Form.Label>{t("interestedBY")}:</Form.Label>
                  <Form.Check
                    type={"checkbox"}
                    id={"tpi-bootcamp"}
                    label={t("tpiBootcamp")}
                    name="tpiBootcamp"
                  />
                  <Form.Check
                    type={"checkbox"}
                    id={"tpi-first-aid"}
                    label={t("tpiFirstAid")}
                    name="tpiFirstAid"
                  />
                </div>
              </Form.Group>
            </Row>

            <Form.Group className="mb-3">
              <FloatingLabel
                controlId="customerMessage"
                label={t("YourMessage")}
              >
                <Form.Control
                  as="textarea"
                  style={{ height: "100px" }}
                  name="customerMessage"
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <Recaptcha
                sitekey="6LeGSn4fAAAAAEnl8IeCbm0WJaw3vFZEhvgWMCRI"
                render="explicit"
                onloadCallback={recaptchaLoaded}
                verifyCallback={verifyCallback}
              />
            </Form.Group>

            <Button className="cmp-contact_submit-button" type="submit">
              {t("submit")}
            </Button>
            <br />
            <br />
          </Form>
        </div>
      </div>
      <div className="cmp-contact__image" />
    </div>
  );
};

export default Contact;
