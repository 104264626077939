import React from "react";

const ToolDescription = ({ t }) => {
  return (
    <div className="cmp-tool-description__container">
      <div className="cmp-tool-description__image-container">
        <div className="cmp-tool-description__image" />
      </div>
      <div className="cmp-tool-description__summary">
        <p className="cmp-tool-description__summary-title">
          {t("toolDescription-title")}
        </p>
        <p className="cmp-tool-description__summary-text">
          {t("toolDescription")}
        </p>
      </div>
    </div>
  );
};

export default ToolDescription;
