import React from "react";
import TypeAnimation from "react-type-animation";

const Hero = ({ t }) => {
  return (
    <div className="cmp-hero__container top">
      <p className="cmp-hero__container-title">
        {t("Hero_phrase")} <br />
        <TypeAnimation
          className="cmp-hero__container-title-span"
          cursor={true}
          sequence={[
            t("Hero_word_1"),
            2000,
            t("Hero_word_2"),
            2000,
            t("Hero_word_3"),
            2000,
          ]}
          wrapper="span"
          repeat={3}
        />
      </p>
      <div className="cmp-hero__container-description"></div>
    </div>
  );
};

export default Hero;
