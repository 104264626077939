import React from "react";
import ReactPlayer from "react-player";

const BusinessDescription = ({ t }) => {
  return (
    <div className="cmp-business-description__container" id="about">
      <div className="cmp-business-description__summary">
        <p className="cmp-business-description__summary-title">
          {t("whatIsTeamMate")}
        </p>
        <p className="cmp-business-description__summary-text">
          {t("what__description_1")}
          <br /> <br />
          {t("what__description_2")}
        </p>
      </div>
      <div className="cmp-business-description__image-container">
        <ReactPlayer
          className="video_container"
          url="https://youtu.be/bi-FsxQTGo4"
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default BusinessDescription;
